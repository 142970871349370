<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>小程序管理</BreadcrumbItem>
                <BreadcrumbItem>推荐职位</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Select v-model="filter.customerId" filterable clearable>
                        <Option v-for="item in customerArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="职位名称" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.readOnly && row.isRecommended === 0" @click.native="handleRecommended(row, index)">推荐</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del && row.isRecommended === 1" @click.native="handleDel(row, index)">取消推荐</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
            <view-position v-model="showViewModal" :data="data"></view-position>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {
    reqDelRecommendPosition,
    reqGetCustomerArray,
    reqGetPositionList, reqGetPositionPage,
    reqRecommendPosition
} from "../../../api/admin-api";
import viewPosition from "../wcCustomer/positionView.vue"
import {SERVER_HOST} from "../../../common/portConfig";

export default {
    name: 'wcPositionList',
    components: {
        viewPosition
    },
    data() {
        return {
            id: '',
            data: {},
            filter: {
                keywords: '',
                customerId: null
            },
            showViewModal: false,
            loading: false,
            dataList: [],
            customerArray : [],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                readOnly: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '职位名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '招聘企业',
                    key: 'customerName',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '薪资范围',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.salaryFrom === -1 ? '面议' : params.row.salaryFrom + 'K - ' + params.row.salaryTo + 'K'
                        )
                    },
                },
                {
                    title: '学历要求',
                    key: 'educationName',
                    tooltip: 'true',
                    width: 120,
                },
                {
                    title: '招聘人数',
                    key: 'recruitingNumbers',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '是否推荐',
                    width: 100,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.isRecommended === 0 ? '否' : '是'
                        )
                    }
                },
                {
                    title: '更新人',
                    key: 'updatedByName',
                    width: 120,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getCustomer() {
            reqGetCustomerArray().then((res)=>{
                if (res.data.code === 1){
                    this.customerArray = res.data.data
                }else {
                    this.customerArray = []
                }
            }).catch(()=>{
                this.customerArray = []
            })
        },
        getPositionPage() {
            this.loading = true;
            this.dataList = [];
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                customerId: this.filter.customerId
            };
            reqGetPositionPage(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch((err) => {
                this.dataList = [];
                this.loading = false;
            });
        },
        resetting() {
            this.filter.keywords = '';
            this.filter.customerId = null;
            this.getPositionPage();
        },
        handleView(row, index) {
            this.data = row
            this.data.imgData = SERVER_HOST + '/resource/public/' + row.currentLogoName;
            this.showViewModal = true;
        },
        handleRecommended(row,index) {
            reqRecommendPosition({id:row.id,isRecommend: 1}).then((res)=>{
                if (res.data.code===1){
                    this.getPositionPage();
                    this.$Message.success('推荐职位成功');
                }
            }).catch(()=>{
                this.$Message.error("推荐职位成功")
            })
        },
        handleDel(row, index) {
            reqRecommendPosition({id:row.id,isRecommend: 0}).then((res)=>{
                if (res.data.code===1){
                    this.getPositionPage();
                    this.$Message.success('取消推荐职位成功');
                }
            }).catch(()=>{
                this.$Message.error("取消推荐职位失败")
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getPositionPage();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getPositionPage();
        },
        doSearching() {
            this.pageNum = 1;
            this.getPositionPage();
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        this.getCustomer();
        this.getPositionPage();
    }
}
</script>
